@use 'swiper/css';
@import 'src/utils/utils';

.tarifs {
  margin-top: 15px;
  margin-right: 16px;
  margin-bottom: 15px;
  margin-left: 16px;

  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(min-width: $xxl) {
    @include box;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media(min-width: $lg) {
      gap: 15px;
    }

    @media(min-width: $xxl) {
      max-width: 800px;
    }
  }

  &__header {
    margin-right: -16px;
    margin-left: -16px;

    @media(min-width: $md) {
      display: grid;
      align-items: center;
      margin-right: 0;
      margin-left: 0;
      grid-template-columns: 155px minmax(0, 1fr);
      gap: 5px;
    }
  }

  &__header-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    background-color: $Main-2;
    flex-grow: 1;
    gap: 16px;

    @media(min-width: $md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-radius: 10px;
    }
  }

  &__name {
    @include Text-13-reg;
    display: none;
    margin: 0;
    flex-shrink: 0;

    @media(min-width: $md) {
      display: unset;
    }
  }

  &__line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__line-name {
    @include Text-13-reg;
    width: 100%;
    max-width: 150px;
    margin: 0;
    flex-shrink: 0;
  }

  &__swiper {
    width: calc(100% + 16px);
    margin-right: -16px;
    padding-right: 16px;

    @media(min-width: $lg) {
      width: calc(100% + 30px);
      margin-right: -30px;
      padding-right: 30px;
    }
  }

  &__swiper-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
  }

  &__slide {
    width: 150px;
    height: unset;

    @media(min-width: $md) {
      width: 160px;
    }
    @media(min-width: $lg) {
      width: 106px;
    }

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius:  0 10px 10px 0;
    }

    &:nth-child(odd) {
      background-color: $Main-2;
    }

    &:nth-child(even) {
      background-color: $Main-3;
    }

    &--active {
      background-color: rgba($color: #49AD63, $alpha: 30%) !important;

      .tarifs {

        &__th,
        &__td {
          border-color: rgba($color: #49AD63, $alpha: 40%);

          &--border-bottom {
            border-color: $Alternative-4;
          }
        }

        &__button {
          color: $Alternative-4;
          background-color: $Main-2;
        }
      }
    }
  }

  &__table {
    display: table;
    display: grid;
    grid-template-columns: 130px minmax(0, 1fr);
    gap: 5px;

    @media(min-width: $md) {
      grid-template-columns: 155px minmax(0, 1fr);
    }

    &--ready {

      .tarifs {

        &__show-in {
          animation: show-in 0.5s ease-out 1;
          opacity: 1;
        }
      }
    }
  }

  &__sub-table {
    margin-top: -5px;
    margin-bottom: -5px;

    .tarifs__th {
      padding-top: 5px;
      padding-bottom: 5px;
      border: 0;

      &:not(:first-child) {
        padding-left: 15px;
      }
    }

    .tarifs__td {
      padding-top: 5px;
      padding-bottom: 5px;
      border: 0;
    }
  }

  &__show-in {
    opacity: 0;
  }

  &__tr {
    display: table-row;
  }

  &__th {
    @include Text-13-reg;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid $Main-5;

    @media(min-width: $md) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &:last-child {
      border-bottom: unset;
    }

    &--border-bottom {
      border-width: 3px;
      border-color: $Main-1;
    }
  }

  &__td {
    @include Text-12-reg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-bottom: 2px solid $Main-5;

    @media(min-width: $md) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &:last-child {
      border-bottom: unset;
    }

    em {
      font-style: normal;
      color: $t-60;
    }

    span {
      margin-bottom: auto;

      & + .tarifs__button {
        margin-top: 15px;
      }
    }

    &--border-bottom {
      border-width: 3px;
      border-color: $Main-1;
    }
  }

  &__icon {
    display: flex;
    width: 26px;
    height: 26px;
    font-style: normal;

    & + span {
      margin-top: 5px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__button {
    @include Text-14-reg;
    margin-top: auto;
    margin-right: 0;
    margin-bottom: auto;
    margin-left: 0;
    padding-top: 4px;
    padding-right: 13px;
    padding-bottom: 6px;
    padding-left: 13px;
    color: $Main-2;
    background: 0;
    border: 0;
    border-radius: 10px;
    background-color: $Corporate-2;
    transition: background-color 0.3s;
    text-align: center;

    &:not(:disabled):not(span):hover {
      @media(min-width: $lg) {
        background-color: $Corporate-1;
        cursor: pointer;
      }
    }

    &:disabled {
      color: $Main-6;
      background-color: $Main-4;
      cursor: not-allowed;
    }
  }

  &__price {
    @include Text-14-bold;
  }

  &__old-price {
    @include Text-12-reg;
    color: $t-60;
  }

  &__monthly {
    @include Text-13-bold;
    margin-top: 3px;
  }

  &__footer {
    margin-top: 5px;

    @media(min-width: $lg) {
      padding-left: 160px;
    }
  }

  &__description {
    @include Text-10-reg;
    margin: 0;
    color: $t-60;
  }

  &__select {
    display: none;
  }

  &__choices {
    margin: 0;
    padding: 0;
    flex-shrink: 0;

    @media(min-width: $md) {
      width: 280px;
    }

    .choices__item {
      @include Text-14-reg;

      em {
        font-weight: 700;
        font-style: normal;
        color: $Main-6;
      }
    }

    .choices__list--single {
      margin: 0;
      padding: 0;
      text-align: left;
      color: currentcolor;

      .choices__item {

        em {
          color: $Corporate-2;
        }
      }
    }

    .choices__list--dropdown {
      @include Shadow-1;
      z-index: 15;
      min-width: 290px;
      margin: 0;
      margin-top: 10px;
      border: 0;
      border-radius: 10px;
      background-color: $Main-2;
      word-break: initial;

      .choices__list {
        padding: 8px;

        &::-webkit-scrollbar {
          @media(min-width: $lg) {
            width: 10px;
          }
        }
      
        &::-webkit-scrollbar-track {
          @media(min-width: $lg) {
            margin-top: 20px;
            margin-bottom: 20px;
            background-color: transparent;
          }
        }
      
        &::-webkit-scrollbar-thumb {
          @media(min-width: $lg) {
            height: 4px;
            border: 3px solid #ffffff;
            border-radius: 8px;
            background-color: #b0b0b0;
          }
        }
      }

      .choices__item {
        @include Text-14-reg;
        margin: 0;
        padding-top: 8px;
        padding-right: 12px;
        padding-bottom: 8px;
        padding-left: 12px;
        text-align: left;
        color: inherit;
        border-radius: 6px;
        transition:
          color 0.3s,
          background-color 0.3s;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
          @media(min-width: $lg) {
            background-color: $Main-4;
          }
        }
      }

      .choices__placeholder {
        display: none;
      }

      .is-highlighted {
        background-color: transparent;
      }

      .is-selected {
  
        em {
          color: $Corporate-2;
        }
      }
    }

    .choices__placeholder {
      opacity: 1;
    }

    &[data-type*='select-one'] .choices__inner {
      display: flex;
      min-height: unset;
      padding-top: 2px;
      padding-right: 30px;
      padding-bottom: 2px;
      padding-left: 0;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      outline: none;

      .choices__item {
        text-align: right;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &[data-type*='select-one']:before {
      position: absolute;
      top: 50%;
      right: 11px;
      width: 10px;
      height: 2px;
      margin: 0;
      border: 0;
      background-color: currentcolor;
      transition:
        color 0.3s,
        transform 0.3s;
      transform: rotate(45deg);
      pointer-events: none;
      content: '';
    }

    &[data-type*='select-one']:after {
      position: absolute;
      top: 50%;
      right: 5px;
      width: 10px;
      height: 2px;
      margin: 0;
      border: 0;
      background-color: currentcolor;
      transition:
        color 0.3s,
        transform 0.3s;
      transform: rotate(-45deg);
      pointer-events: none;
      content: '';
    }

    &[data-type*='select-one'].is-open:before {
      margin: 0;
      color: $Corporate-2;
      transform: rotate(-45deg);
    }

    &[data-type*='select-one'].is-open:after {
      margin: 0;
      color: $Corporate-2;
      transform: rotate(45deg);
    }

    &[data-type*='select-one'].is-open .choices__inner {
      border-radius: 4px;
    }
  }
}